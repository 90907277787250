import Swiper from 'swiper';

jQuery( document ).ready( function ( $ ) {


	var Theme2 = (function () {

		var old_theme_js = function () {

			function setEqHeight() {
				equalheight( '#we-believe .col' );
				//equalheight('#areas-main .item ul');
				equalminheight( '#prepared-resources .item .inner' );
				equalminheight( '#how-help .practice-wrap .item .content' );
				/*
				equalheight('#firm-home .content > div');
				equalheight('#ourfirm .col');
				*/
			};

			$( function () {
				$( 'a[href*=#]:not([href=#]).scroller' ).click( function () {
					if ( location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname ) {
						var target = $( this.hash );
						target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
						if ( target.length ) {
							$( 'html,body' ).animate( {
								scrollTop: target.offset().top - 30
							}, 1000 );
							return false;
						}
					}
				} );
			} );

			$( document ).ready( function () {

				$( '.btn-load-more-ajax a' ).on( 'click', function () {
					var btn = $( this );
					var contentTarget = btn.parent().data( 'target' );
					var appendTo = btn.parent().data( 'append-to' );
					var loadMore = btn.parent().data( 'load-more' );
					$.get( btn.attr( 'href' ), function ( data ) {
						// $(appendTo).append($(data).find(contentTarget));
						var nextBtn = $( data ).find( loadMore ).attr( 'href' );
						if ( nextBtn ) {
							btn.attr( 'href', nextBtn );
						} else {
							btn.parent().hide();
						}
					} );
					return false;
				} );

				var map_home;

				function mapInitialize( map_ ) {
					var coords_ = $( '#' + map_ ).data( 'coords' );

					if ( coords_ ) {
						var tmpCoords = coords_.split( ',' );
						var latitude = tmpCoords[0];
						var longtitude = tmpCoords[1];

						var latlng = new google.maps.LatLng( latitude, longtitude );

						var myOptions = {
							zoom: 16,
							center: latlng,
							disableDefaultUI: true,
							mapTypeId: google.maps.MapTypeId.ROADMAP
						};

						var map = new google.maps.Map( document.getElementById( map_ ), myOptions );

						var marker = new google.maps.Marker( {
							position: latlng,
							map: map
						} );
					}
				};

				function mapInitializeHome( map_ ) {
					var coords_ = $( '#' + map_ ).data( 'coords' );

					if ( coords_ ) {
						var latitude = coords_[0][0];
						var longtitude = coords_[0][1];
						var latlng = new google.maps.LatLng( latitude, longtitude );

						var myOptions = {
							zoom: 16,
							center: latlng,
							disableDefaultUI: true,
							mapTypeId: google.maps.MapTypeId.ROADMAP
						};

						map_home = new google.maps.Map( document.getElementById( map_ ), myOptions );

						$.each( coords_, function ( index, value ) {
							var latitude = value[0];
							var longtitude = value[1];
							var latlng = new google.maps.LatLng( latitude, longtitude );

							var marker = new google.maps.Marker( {
								position: latlng,
								icon: '/wp-content/themes/cw-theme-two/images/ico-pointer.png',
								map: map_home
							} );
						} );
					}
				};

				// ===== Scroll to Top ====
				$( window ).scroll( function () {
					if ( $( this ).scrollTop() >= 50 ) { // If page is scrolled more than 50px
						$( '#return-to-top' ).fadeIn( 300 ); // Fade in the arrow
					} else {
						$( '#return-to-top' ).fadeOut( 300 ); // Else fade out the arrow
					}
				} );
				$( '#return-to-top' ).click( function () {
					$( 'body,html' ).animate( {
						scrollTop: 0
					}, 500 );
				} );

				// Test first (so we can delete this later)
				/*$('.map-home').each(function () {
						var map_ = $(this).attr('id');
						mapInitializeHome(map_);
				});

				$('.map, .map-hold').each(function () {
						var map_ = $(this).attr('id');
						mapInitialize(map_);
				});*/

				/*
				$(".accordion-contacts dt a").click(function (e) {
						/*var coords_ = $(this).data("coords");

						if (coords_) {
								var tmpCoords = coords_.split(',');
								var latitude = tmpCoords[0];
								var longtitude = tmpCoords[1];
								var latlng = new google.maps.LatLng(latitude, longtitude);
								map_home.setCenter(latlng);
						}*/
				/*
				var image = $(this).data("image");
				$("#map-home").css("background-image",'url(' + image + ')');
		});*/

				//////////////////  NO LONGER USING DUE TO CONFLICT WITH PEGASAAS
				/*
				$('.with-bg, .item header').each(function (e) {
						var bg_ = 'url(' + $(this).find('> img').attr('src') + ')';
						$(this).find('> img').hide();
						$(this).css('background-image', bg_);
				});

				$('.no-backgroundsize header, .no-backgroundsize .with-bg').each(function (e) {
						var bg_ = $(this).find('>img').attr('src');
						$(this).find('>img').hide();
						$(this).css({
								"filter": "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='" + bg_ + "', sizingMethod='scale')",
								"-ms-filter": "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='" + bg_ + "', sizingMethod='scale')"
						});
				});
				*/
				//////////////////

				var TIMEOUT = 300;
				// $('body').wrapInner('<div class="page-inner-area"/>');
				// $('body > .page-inner-area').wrapInner('<div id="wrapper"/>');
				$( '<span class="fader"/>' ).appendTo( '.page-inner-area' ).css( 'opacity', 0 );
				$( '.but-mobile' ).click( function () {
					if ( ! $( 'body' ).hasClass( 'open-menu' ) ) {
						$( 'body' ).addClass( 'open-menu' );
						$( '.fader' ).fadeIn();
					} else {
						$( 'body' ).removeClass( 'open-menu' );
						$( '.fader' ).fadeOut();
					}
					return false;
				} );

				$( '.fader' ).click( function () {
					$( 'body' ).removeClass( 'open-menu' );
					$( '.fader' ).fadeOut();
				} );

				$( '.menu-main > ul li' ).has( '> ul' ).addClass( 'has-drop' );

				$( '.menu-main .has-drop > a' ).each( function () {
					$( '<span class="opener visible-xs visible-sm"/>' ).appendTo( this );
				} );

				$( '.menu-main .has-drop > a .opener' ).click( function ( e ) {
					$( this ).closest( 'li' ).find( '> ul' ).slideToggle();
					$( this ).closest( 'li' ).toggleClass( 'open' );
					return false;
				} );

				$( ".side-address a.but-hours" ).on( "click", function ( e ) {
					e.preventDefault();
					var parent = $( this ).parents( ".side-address" );
					var container = parent.find( ".popup-hours" );
					var delta = parent.find( ".address-header" ).innerHeight();
					var height = container.innerHeight();
					var position = $( this ).position();
					var top = position.top + delta;
					container.css( "top", top - height - 15 );
					container.toggleClass( "visible" );
					var offsetButton = $( this ).offset().left;
					var offsetContainer = container.offset().left;
					container.find( ".popup-arrow" ).css( "left", (offsetButton - offsetContainer + $( this ).innerWidth() / 2) - 10 );
				} );

				$( ".side-address a.but-close" ).on( "click", function ( e ) {
					e.preventDefault();
					var parent = $( this ).parents( ".popup-hours" );
					parent.removeClass( "visible" );
				} );

				var slider_results = $( "#slider-results" );

				slider_results.owlCarousel( {
					items: 6,
					itemsDesktop: [ 1200, 4 ],
					itemsDesktopSmall: [ 992, 2 ],
					itemsMobile: [ 767, 1 ],
					pagination: false,
					navigation: true
				} );

				let swiper;

				function initTeamSlider() {
					let windowWidth = $( window ).width();

					if ( windowWidth < 768 ) {
						if ( ! swiper ) {
							swiper = new Swiper( '#slider-team', {
								slidesPerView: 1.2,
								spaceBetween: 20,
								loop: true,
								navigation: false,
								pagination: false
							} );
						}
					} else {
						if ( swiper ) {
							swiper.destroy( true, true );
							swiper = null;
						}
					}
				}

				initTeamSlider();

				$( window ).resize( function () {
					initTeamSlider();
				} );

				var slider_testimonial = $( ".testimonial-slider" );

				slider_testimonial.owlCarousel( {
					items: 1,
					itemsDesktop: [ 1200, 1 ],
					itemsDesktopSmall: [ 992, 1 ],
					itemsMobile: [ 768, 1 ],
					pagination: true,
					navigation: false
				} );

				var slider_awards = $( "#slider-awards" );

				slider_awards.owlCarousel( {
					items: 4,
					itemsDesktop: [ 1200, 4 ],
					itemsDesktopSmall: [ 992, 3 ],
					itemsMobile: [ 767, 1 ],
					pagination: false,
					navigation: true
				} );

				var slider_awards_5 = $( "#slider-awards-5" );

				slider_awards_5.owlCarousel( {
					items: 5,
					itemsDesktop: [ 1200, 5 ],
					itemsDesktopSmall: [ 992, 3 ],
					itemsMobile: [ 767, 1 ],
					pagination: false,
					navigation: true
				} );

				var video_carousel = $( '#video-carousel' );

				video_carousel.owlCarousel( {
					items: 2,
					itemsDesktop: [ 1200, 2 ],
					itemsDesktopSmall: [ 992, 2 ],
					itemsMobile: [ 768, 1 ],
					pagination: false,
					navigation: true,
					margin: 48
				} );

				var slider_staff = $( "#slider-staff" );

				slider_staff.owlCarousel( {
					items: 2,
					itemsDesktopSmall: [ 991, 2 ],
					itemsMobile: [ 767, 1 ],
					pagination: false,
					navigation: true
				} );

				var slider_verdicts = $( "#slider-verdicts" );

				if ( slider_verdicts.length ) {
					slider_verdicts.owlCarousel( {
						singleItem: true,
						pagination: false,
						navigation: true,
					} );
				}

				$( "#areas .areas-block .opener" ).click( function () {
					e.preventDefault();
					var container = $( this ).parent().find( "ul" );
					container.toggleClass( "opened" );
				} );

				$( ".btn-go-url" ).click( function ( e ) {
					e.preventDefault();
					var parent = $( this ).parent(),
						href = parent.find( "select option:selected" ).val();
					window.location = href;
				} );

				$( "[placeholder]" ).textPlaceholder();

				$( '.accordion > dt > a' ).click( function ( e ) {
					e.preventDefault();
					var parent = $( this ).parents( "dt" );
					if ( ! parent.hasClass( "accordion-opened" ) ) {
						$( ".accordion > dt.accordion-opened" ).removeClass( "accordion-opened" );
						parent.addClass( "accordion-opened" );
						var targetId = $( this ).attr( "href" );
						var target = $( targetId );
						var current = $( ".accordion > dd.accordion-opened" );
						target.addClass( "accordion-opened" );
						current.removeClass( "accordion-opened" );
						current.slideUp( 400 );
						target.slideDown( 400 );
					}
				} );

				if ( $( "#video-background" ).length ) {
					document.getElementById( 'video-background' ).play();
				}

				// cw video colorbox repsonsive resizer
				var resizeTimer;

				function resizeColorBox() {
					if ( resizeTimer ) clearTimeout( resizeTimer );
					resizeTimer = setTimeout( function () {
						if ( $( '#cboxOverlay' ).is( ':visible' ) ) {
							$.colorbox.load( true );
						}
					}, 300 )
				}

				$( window ).resize( resizeColorBox );
				window.addEventListener( "orientationchange", resizeColorBox, false );

			} );

			$( window ).load( function () {
				// setEqHeight();
			} );

			$( window ).resize( function () {
				// setEqHeight();
			} );

		};

		var toc = function () {

			if ( $( ".toc-wrap" ).length ) {

				var ToC =
					"<div class='useful-info'><h3 class='text-center'>Table of Contents</h3><ul class='news-list'>";

				var newLine, el, title, link;

				$( ".toc-heading" ).each( function () {

					el = $( this );
					title = el.text();
					link = "#" + el.attr( "id" );

					newLine =
						"<li>" +
						"<a class='toc-link' href='" + link + "'>" +
						title +
						"</a>" +
						"</li>";

					ToC += newLine;

				} );

				ToC += "</ul></div>";

				$( ".toc-wrap" ).prepend( ToC );
				//$('.toc-container').smoothScroll();

			}

		};

		var faqs = function () {

			$( '.qa-faqs h3 a' ).on( 'click', function ( event ) {
				event.preventDefault();
				$( this ).stop().toggleClass( 'active' );
				$( this ).parent().toggleClass( 'open' );

			} );

		};

		var testing = function () {
			console.log( 'test...' );
		};

		return {
			init: function () {
				old_theme_js();
				//testing();
				faqs();
				toc();
			}
		}

	})();

	Theme2.init();

	$( '.location-selector' ).on( 'change', function () {
		let maps = {
			'footer-contacts-1': '2658',
			'footer-contacts-3': '2630',
			'footer-contacts-5': '2610',
			'footer-contacts-7': '2607',
			'footer-contacts-9': '23081',
		}

		let location = $( this ).val();
		console.log( location );

		$( '[id^="footer-contacts"]' ).hide();
		$( '#' + location ).show();

		$( '.map-home' ).hide();
		$( '.map-home-' + maps[location] ).show();
	} );

	$( window ).scroll( function () {
		if ( $( window ).scrollTop() > 0 && $( window ).width() < 767 ) {
			let $mobileHeaderTop = $( '.mobile-header-top' ),
				$topLine = $( '.topline' ),
				headerHeight = $mobileHeaderTop.outerHeight() + $topLine.outerHeight();

			$( 'body' ).addClass( 'mobile-sticky' );

			// $topLine.css( 'top', $mobileHeaderTop.outerHeight() + 'px' );
			$( 'header.with-bg' ).css( 'margin-top', headerHeight - 20 + 'px' );
		} else {
			$( 'body' ).removeClass( 'mobile-sticky' );

			$( 'header.with-bg' ).css( 'margin-top', '0px' );
		}
	} );

	$( '.ginput_recaptcha' ).each( function ( i ) {
		var thisID = $( this ).attr( 'id' );
		$( this ).attr( 'id', thisID + '_' + i );
	} );

	// $( '#slider-team' ).owlCarousel({
	//     loop: false,
	//     margin: 20,
	//     nav: false,
	//     dots: false,
	//     autoplay: false,
	//     responsive: {
	//         0: { items: 1.5 }
	//     }
	// });

	document.addEventListener( "DOMContentLoaded", function () {
		document.querySelectorAll( ".wp-block-embed-youtube iframe" ).forEach( function ( iframe ) {
			let videoId = iframe.src.split( "/embed/" )[1].split( "?" )[0];
			let wrapper = iframe.parentElement;

			let overlay = document.createElement( "div" );
			overlay.classList.add( "custom-yt-overlay" );
			overlay.style.backgroundImage = `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`;

			wrapper.appendChild( overlay );

			overlay.addEventListener( "click", function () {
				iframe.style.visibility = "visible";
				overlay.remove();
			} );
		} );
	} );

	setTimeout( function () {
		$( '.wp-block-embed-youtube iframe, #video-carousel .owl-item iframe' ).each( function () {
			let $this = $( this );
			let videoSrc = $this.attr( 'src' );
			console.log( videoSrc );
			let videoId = videoSrc.split( '/embed/' )[1].split( '?' )[0];
			let $parent = $this.parent();

			let autoplaySrc = videoSrc.includes( '?' ) ? videoSrc + '&autoplay=1' : videoSrc + '?autoplay=1';

			let overlay = document.createElement( "div" );
			overlay.classList.add( "custom-yt-overlay" );
			overlay.style.backgroundImage = `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`;

			$parent.append( overlay );

			overlay.addEventListener( "click", function () {
				$this.attr( 'src', autoplaySrc );
				$this.css( 'visibility', 'visible' );

				setTimeout( function () {
					overlay.remove();
				}, 500 );
			} );
		} );

	}, 100 );

	// Video Search
	let typingTimer;
	let doneTypingInterval = 500;

	$( '#search-video' ).keyup( function () {
		$( 'form .ab-search' ).hide();
		$( 'form .close' ).show();
		clearTimeout( typingTimer );
		typingTimer = setTimeout( doAjaxSearch, doneTypingInterval );
	} );

	$( '#search-video' ).keydown( function () {
		clearTimeout( typingTimer );
	} );

	function doAjaxSearch() {
		let search = $( '#search-video' ).val();

		if ( search.length < 1 ) {
			return;
		}

		$.ajax( {
			url: theme_ajax.url,
			method: 'POST',
			data: {
				action: 'video_search',
				search: search,
				nonce: theme_ajax.nonce
			},
			beforeSend: function () {
				$( '.search-results' ).addClass( 'active' );
				$( '.results-body' ).html( `<span class="p-1 text-center d-block">Loading...</span>` );
				$( '.results-footer .results-count' ).text( 'Loading...' );
			},
			success: function ( response ) {
				let items = '';
				if ( response.count ) {
					$.each( response.titles, function ( i, title ) {
						items += `<li class="item">
                            <span class="icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="26" height="26" rx="13" fill="#F2F2F3"/>
                                    <path d="M12.0004 15.9997C12.5257 15.9997 13.0458 15.8962 13.5312 15.6952C14.0165 15.4942 14.4574 15.1995 14.8288 14.8281C15.2003 14.4567 15.4949 14.0157 15.6959 13.5304C15.897 13.0451 16.0004 12.525 16.0004 11.9997C16.0004 11.4744 15.897 10.9543 15.6959 10.469C15.4949 9.98365 15.2003 9.54269 14.8288 9.17126C14.4574 8.79982 14.0165 8.50519 13.5312 8.30417C13.0458 8.10315 12.5257 7.99968 12.0004 7.99968C10.9396 7.99968 9.92214 8.42111 9.17199 9.17126C8.42184 9.9214 8.00042 10.9388 8.00042 11.9997C8.00042 13.0606 8.42184 14.078 9.17199 14.8281C9.92214 15.5783 10.9396 15.9997 12.0004 15.9997ZM16.2138 15.2704L18.6004 17.657C18.664 17.7186 18.7148 17.7922 18.7497 17.8735C18.7845 17.9549 18.8029 18.0424 18.8036 18.1309C18.8043 18.2194 18.7874 18.3072 18.7538 18.3891C18.7202 18.471 18.6706 18.5454 18.608 18.6079C18.5454 18.6705 18.4709 18.7199 18.3889 18.7534C18.307 18.7869 18.2192 18.8037 18.1307 18.8028C18.0422 18.802 17.9547 18.7836 17.8734 18.7486C17.7921 18.7136 17.7185 18.6627 17.6571 18.599L15.2704 16.2124C14.1987 17.0443 12.8503 17.4365 11.4996 17.3091C10.1489 17.1818 8.89749 16.5446 8.00012 15.5271C7.10275 14.5096 6.62687 13.1883 6.66937 11.8323C6.71186 10.4763 7.26953 9.18743 8.22885 8.22812C9.18817 7.2688 10.477 6.71113 11.833 6.66863C13.189 6.62614 14.5103 7.10202 15.5278 7.99939C16.5453 8.89675 17.1826 10.1482 17.3099 11.4989C17.4372 12.8495 17.045 14.198 16.2131 15.2697L16.2138 15.2704Z" fill="#9FB0CC"/>
                                </svg>
                            </span>
                            <span class="title">${title}</span>
                        </li>`;
					} );
				}

				$( '.results-body' ).html( `<ul class="results">${items}</ul>` );
				$( '.results-footer .results-count' ).text( `${response.count} results` );
			},
			error: function () {
				alert( 'Something went wrong' );
			}
		} )
	}

	$( 'body' ).on( 'click', '.results .item', function () {
		let text = $( this ).find( '.title' ).text();
		$( '#search-video' ).val( text );
		$( '.search-results' ).removeClass( 'active' );
		$( 'form .ab-search' ).show();
		$( 'form .close' ).hide();
	} );

	$( 'form .close' ).on( 'click', function () {
		$( '#search-video' ).val( '' );
		$( '.search-results' ).removeClass( 'active' );
		$( 'form .ab-search' ).show();
		$( 'form .close' ).hide();
		$( '.results-body' ).html( `<span class="p-1 text-center d-block">Loading...</span>` );
		$( '.results-footer .results-count' ).text( 'Loading...' );
	} );

	$( '.resources-cat-list a' ).on( 'click', function ( e ) {
		e.preventDefault();

		let $this = $( this ),
			target = $this.data( 'target' ),
			search = '';

		const params = new URLSearchParams( window.location.search );
		if ( window.location.search.includes( 'search' ) ) {
			search = params.get( 'search' );
		}

		if ( $this.closest( 'li' ).hasClass( 'active' ) ) {
			// Reset
			$( '.resources-cat-list a' ).closest( 'li' ).removeClass( 'active' );

			if ( search.length ) {
				$( '.cw_video_item' ).addClass( 'active' );
			} else {
				$( '.vid-listing-container' ).addClass( 'active' );
			}
		} else {
			if ( search.length ) {
				$( '.cw_video_item' ).removeClass( 'active' );
				$( `.cw_video_item[data-category="${target}"]` ).addClass( 'active' );
			} else {
				$( '.vid-listing-container' ).removeClass( 'active' );
				$( '.vid-listing-container.' + target ).addClass( 'active' );
			}

			$( '.resources-cat-list a' ).closest( 'li' ).removeClass( 'active' );
			$this.closest( 'li' ).addClass( 'active' );
		}
	} );

	// Go to page
	$( '#pages-dropdown' ).on( 'change', function () {
		window.location.href = $( this ).val();
	} );

	const fixedHeader = function () {
		let headerBottom = $( '.topline' ).outerHeight(),
			headerTop = $( '#top-lang-banner' ).outerHeight(),
			scrollTop = 50,
			totalHeaderHeight = headerBottom + headerTop;

		if ( $( window ).width() < 768 ) {
			headerTop = $( '.fixed-menu' ).outerHeight();
			scrollTop = 0;
		}

		totalHeaderHeight = headerBottom + headerTop;

		if ( $( window ).scrollTop() > scrollTop ) {
			$( 'body' ).addClass( 'header-sticky' );
			$( 'body' ).css( 'margin-top', totalHeaderHeight + 'px' );
		} else {
			$( 'body' ).removeClass( 'header-sticky' );
			$( 'body' ).css( 'margin-top', '0px' );
		}
	}

	$( window ).scroll( fixedHeader );
} );
